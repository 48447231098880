<template>
  <div class="container">
      <div class="box">
        <div class="item avatar">
          <label for="android" v-if="isAndroid">
            <input id="android" type="file" @change="handlerImage" ref="inputer" accept="image/*" data-id='an' />
          </label>
          <label for="ios" v-else>
            <input id="ios" type="file" @change="handlerImage" ref="inputer" accept="image/*;capture=user" data-id='ios' />
          </label>
          <span><img :src="userInfo.headPortrait" alt="" title="" /></span>头像
        </div>
      </div>
      <div class="box">
        <div class="item text">
          <label for="nickname"><span><input id="nickname" type="text" v-model="userInfo.nickname"></span>昵称</label>
        </div>
        <div class="item">
          <select id="sex" v-model="userInfo.sex"><option v-for="(item, index) in sex" :key="index" :value="item.text">{{item.text}}</option></select>
          <span>{{userInfo.sex}}</span>性别
        </div>
        <div class="item date">
          <label for="birthday"><span>{{userInfo.birthday}}</span>出生年月<input id="birthday" type="date" v-model="userInfo.birthday"></label>
        </div>
        <div class="item">
          <label @click="showCitys"><span>{{userInfo.cityName}}</span>所在地区</label>
        </div>
        <div class="item text tel" style="display:none;">
          <label for="phone"><span><input id="phone" type="tel" v-model="userInfo.phoneNumber" maxlength="11" @input="removeSpaces" /></span>绑定手机</label>
        </div>
        <div class="item text verify" v-show="userInfo.phoneNumber.replace(/\s/g, '') != '' && userInfo.phoneNumber != oldPhoneNumber">
          <label for="verify"><span><input id="verify" type="text" v-model="userInfo.verifyCode" maxlength="8" /></span>验证码</label>
          <a href="javascript:;" @click="getCode" :class="geting ? 'disable' : ''">{{getCodeText}}</a>
        </div>
      </div>
      <div class="btn">
        <a href="javascript:;" @click="save">保存</a>
      </div>
      <div class="citylist" v-show="showCityList">
        <div class="cancel" @click="showCityList = false">取消</div>
        <div class="anchor">
          <div class="box">
            <p v-for="(item, index) in cityList" :key="index">
              <!-- <router-link :to="'/edit#' + item.name"></router-link> -->
              <a href="javascript:;" @click="tocity(item.name)">{{item.name}}</a>
            </p>
          </div>
        </div>
        <div class="list">
          <div class="item" v-for="(item, index) in cityList" :key="index">
            <h1 :id="item.name">{{item.name}}</h1>
            <p v-for="(city, cityIndex) in item.citys" :key="cityIndex" @click="changeCity(city)">{{city.cityName}}</p>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 || u.indexOf("Linux") > -1;   //android终端
  var phoneNumberVerification = /^1[3456789]\d{9}$/;
  var waiting = localStorage.getItem('waiting')
  export default {
    name: 'Edit',
    data () {
      return{
        urls: {
          userInfo: 'userInfo/findUserInfo', // 获取用户信息地址
          citys: 'baseCity/queryAllAndGroupByInitials', // 所有城市列表
          upload: 'upload', // 头像上传
          updateUserInfo: 'userInfo/updateUserInfo', // 修改用户信息
          getLoginCode: 'getLoginCode', // 获取短信
          updateUserPhone: 'userInfo/updateUserPhone' // 绑定手机号
        },
        isAndroid: false, // 检测设备是否为安卓
        // 用户信息
        userInfo: {
          headPortrait: '', // 头像路径
          nickname: '', // 昵称
          sex: '', // 性别
          birthday: '', // 出生日期
          cityCode: '', // 城市编码
          cityName: '', // 城市名称
          phoneNumber: '', // 手机号码
          verifyCode: '' // 验证码
        },
        oldPhoneNumber: '', // 旧的手机号码
        geting: false, // 验证码获取中
        getCodeText: '获取验证码', // 按钮文本
        sex: [
          { text: '男', code: 1},
          { text: '女', code: 0}
        ],
        cityList: [], // 城市列表
        showCityList: false, // 显示城市列表
        infoEdit: false, // 信息编辑提交结果
        phoneEdit: false // 手机号码编辑提交结果
      }
    },
    mounted(){
      console.log('完善资料')
      this.isAndroid = isAndroid
      this.getUserInfo()
      this.getCitys()
      // 查看是否获取过验证码
      if(waiting){
        var myDate = new Date()
        if(myDate.getTime() - waiting < 60000){
          this.geting = true
          this.leftTime(myDate.getTime())
        }else{
          localStorage.removeItem('waiting')
        }
      }
    },
    created(){

    },
    methods: {
      // 获取用户信息
      getUserInfo() {
        const me = this;
        this.$post({
          url: this.urls.userInfo,
          params:{
            token: this.$global.token,
            deviceId: ''
          }
        }).then(rsp => {
          console.log(rsp)
          me.userInfo.headPortrait = rsp.data.headPortrait
          me.userInfo.nickname = rsp.data.nickname
          if(rsp.data.sex != null || rsp.data.sex != ''){
            var sex = ['女','男']
            me.userInfo.sex = sex[rsp.data.sex]
          }
          if(rsp.data.birthday){
            me.userInfo.birthday = rsp.data.birthday
          }
          if(rsp.data.cityCode){
            me.userInfo.cityCode = rsp.data.cityCode
          }
          if(me.cityList && me.userInfo.cityCode){
            var x,y
            for(x in me.cityList){
              for(y in me.cityList[x].citys){
                if(me.userInfo.cityCode == me.cityList[x].citys[y].cityCode){
                  console.log(me.cityList[x].citys[y])
                  me.userInfo.cityName = me.cityList[x].citys[y].cityName
                }
              }
            }
          }
          if(rsp.data.phoneNumber){
            me.userInfo.phoneNumber = rsp.data.phoneNumber
            me.oldPhoneNumber = rsp.data.phoneNumber
          }
        })
      },
      // 获取城市列表
      getCitys() {
        const me = this;
        this.$post({
          url: this.urls.citys,
          params:{}
        }).then(rsp => {
          this.cityList = rsp.data
          if(this.userInfo.cityCode){
            console.log('城市列表，显示所在地区')
            var x, y;
            for(x in rsp.data){
              for(y in rsp.data[x].citys) {
                if(this.userInfo.cityCode == rsp.data[x].citys[y].cityCode){
                  this.userInfo.cityName = rsp.data[x].citys[y].cityName
                  console.log(rsp.data[x].citys[y].cityName)
                }
              }
            }
          }
        })
      },
      // 显示所在城市
      showCitys() {
        this.showCityList = true
        setTimeout(function(){
          let box = document.querySelectorAll('.container .citylist .list')
          box[0].scrollTop = 0
        },10)
      },
      // 选择城市
      changeCity(city) {
        this.showCityList = false
        this.userInfo.cityName = city.cityName
        this.userInfo.cityCode = city.cityCode
      },
      // 图片上传
      handlerImage(){
        let that = this;
        let file = this.$refs.inputer.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          let content = this.result;
          if(content){
            let img = new Image();
            img.src = content;
            img.onload = function (){
              let q = file.size > 64*1024 ? 64*1024/file.size : 1;
              that.compressEvent(img, q,(dataURL)=> {
                that.uploadImg(dataURL);
              })
            }
          }
        };
      },
      // 压缩并转码
      compressEvent(img, q, callback){
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        // let width = img.width;
        // let height = img.height;
        let width = 960;
        let height = img.height * 960 / img.width;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        let dataURL = canvas.toDataURL("image/jpeg", q);
        callback ? callback(dataURL):null;
      },
      // 将base64转为文件
      dataURLtoFile(dataurl, filename) { //将base64转换为文件
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
          type: mime
        });
      },
      // 提交图片
      uploadImg(base64) {
        const me = this;
        // var imageBase64 = base64.split(';base64,')[1]
        var file = this.dataURLtoFile(base64, 'avatar.jpg')
        var params = new FormData()
        params.append('file', file)
        this.$post({
          url: this.urls.upload,
          params: params
        }).then(rsp => {
          this.userInfo.headPortrait = rsp.data.filePath
          console.log(rsp)
        })
      },
      // 保存
      save() {
        let me = this
        let sex = '', editType = 1
        if(this.userInfo.sex == '男'){
          sex = 1
        } else if(this.userInfo.sex == '女'){
          sex = 0
        }
        if(this.userInfo.nickname.replace(/\s/g, '') == ''){
          Toast('请输入用户昵称')
          return
        }
        let myDate = new Date()
        let year = myDate.getFullYear()
        let month = myDate.getMonth() + 1
        let day = myDate.getDate()
        if(month < 10) {
          month = '0' + month
        }
        if(day < 10) {
          day = '0' + day
        }
        let today = year + '-' + month + '-' + day
        today = parseInt(today.replace(/-/g, ''))
        let birthday = parseInt(this.userInfo.birthday.replace(/-/g, ''))
        if(birthday && birthday > today) {
          Toast('生日日期不正确，请重新选择日期！')
          return
        }
        if(!this.userInfo.cityCode){
          Toast('请选择所在地区')
          return
        }
        if(this.userInfo.phoneNumber && this.userInfo.phoneNumber != this.oldPhoneNumber) {
          editType = 2
          if (!phoneNumberVerification.test(this.userInfo.phoneNumber)) {
            Toast('手机号码输入有误')
            return
          }
          if(this.userInfo.verifyCode.replace(/\s/g, '') == '') {
            Toast('请输入验证码')
            return
          }
        }
        // Toast.loading({ message: '加载中...', forbidClick: true, });
        this.$post({
          url: this.urls.updateUserInfo,
          params: {
            headPortrait: this.userInfo.headPortrait,
            nickname: this.userInfo.nickname,
            birthday: this.userInfo.birthday,
            sex: sex,
            cityCode: this.userInfo.cityCode,
            token: this.$global.token
          }
        }).then(rsp => {
          Toast.loading({ message: '', forbidClick: false, });
          if(rsp.status == 1) {
            if(editType == 1){
              Toast('修改成功！')
              setTimeout(() => {
                this.$router.push({
                  path:'/my'
                })
              },2000)
            }else{
              me.infoEdit = true
              me.toMy()
            }
          }else {
            Toast(rsp.message)
          }
        })
        if(this.userInfo.phoneNumber != this.oldPhoneNumber){
          this.$post({
            url: this.urls.updateUserPhone,
            params: {
              token: this.$global.token,
              phoneNumber: this.userInfo.phoneNumber,
              verifyCode: this.userInfo.verifyCode
            }
          }).then( rsp => {
            if(rsp.status == 1) {
              me.phoneEdit = true
              me.toMy()
            }
          }).catch( error => {
              Toast(error.message)
          })
        }
      },
      // 页面跳转
      toMy() {
        if(this.infoEdit && this.phoneEdit){
          Toast('绑定成功！')
          setTimeout(() => {
            this.$router.push({
              path:'/my'
            })
          },2000)
        }
      },
      // 快速定位
      tocity(city) {
        let nowPosition = document.getElementById(city).offsetTop
        let box = document.querySelectorAll('.container .citylist .list')
        box[0].scrollTop = nowPosition
      },
      // 获取验证码
      getCode() {
        if (this.geting) return;
        if(this.userInfo.phoneNumber.replace(/\s/g, '') == '') {
          Toast('请输入手机号码')
          return
        }
        if (!phoneNumberVerification.test(this.userInfo.phoneNumber)) {
          Toast('手机号码输入有误')
          return
        }
        this.geting = true
        var myDate = new Date()
        this.leftTime(myDate.getTime())
        this.$post({
          url: this.urls.getLoginCode,
          params: {
            phoneNum: this.userInfo.phoneNumber
          }
        }).then(rsp => {
          if(rsp.status != 1) {
            Toast('获取验证码失败！')
          }
        }).catch( error => {
            Toast(error.message)
        })
      },
      // 获取验证码等待倒计时
      leftTime(nowTime) {
        this.geting = true
        var me = this
        var count = 60;
        if(waiting && nowTime - waiting < 60000){
          count = parseInt((60000 - (nowTime - waiting)) / 1000)
        }else{
          localStorage.setItem('waiting', nowTime)
        }
        getSecurityCode()
        var countdown = setInterval(getSecurityCode, 1000);
        function getSecurityCode() {
            me.getCodeText = '重新获取（' + count + '）'
            count--;
            if (count == 0) {
              clearInterval(countdown);
              me.geting = false
              me.getCodeText = '获取验证码'
              localStorage.removeItem('waiting')
            }
        }
      },
      // 移除空格
      removeSpaces() {
        this.userInfo.phoneNumber = this.userInfo.phoneNumber.replace(/\s/g, '')
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    background:  #F7F7F7;
    padding-bottom: 60px;
    box-sizing: border-box;
    .box{
      margin-top: 5px;
      width: 100%;
      display: inline-block;
      .item{
        background: url(../assets/img/icon-more.png) no-repeat 97% center/auto 15px #fff;
        margin-top: 2px;
        text-align: left;
        padding: 10px;
        font-size: 16px;
        line-height: 32px;
        color: #141212;
        box-sizing: border-box;
        position: relative;
        img{
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: inline-block;
          vertical-align: text-bottom;
        }
        span{
          float: right;
          margin-right: 20px;
        }
        input{
          padding: 0;
          border: none;
          text-align: right;
        }
        input[type=file]{
          font-size: 0;
          width: 1px;
          height: 1px;
        }
        select{
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
        label{
          display: block;
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
      .avatar  label{
        position: absolute;
        left: 0;
        top: 0;
      }
      .text label span{
        width: calc(100% - 60px);
        input{
          width: 100%;
        }
      }
      .tel label span{
        width: calc(100% - 90px);
      }
      .verify {
        background: #fff;
        label span{
          width: calc(100% - 90px);
          input{
            text-align: left;
          }
        }
        a{
          position: absolute;
          right: 10px;
          top: 10px;
          color: #21dd53;
        }
        a.disable{
          color: #999;
        }
      }
      .date input{
        width: 1px;
        height: 1px;
        opacity: 0;
        font-size: 0;
        border: none;
        background: none;
        outline: none;
      }
    }
    .btn{
      width: 100%;
      height: 50px;
      background: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      box-shadow: 0 0 5px #ccc;
      a{
        display: block;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(to right, #7ddb4f, #21dd53);
        color: #fff;
        text-align: center;
        font-size: 18px;
        transition: all 0.25s linear;
      }
    }
    .citylist{
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
      background: #fff;
      .cancel{
        position: fixed;
        right: 10px;
        top: 10px;
        font-size: 16px;
        cursor: pointer;
      }
      .anchor{
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 30px;
        box-sizing: border-box;
        padding-top: 20px;
        background: #f0f0f0;
        .box{
          display: flex;
          flex-flow: column;
          flex-wrap: wrap;
          line-height: 3vh;
          text-align: center;
          a{
            font-size: 16px;
            font-weight: bold;
            color: #333;
          }
        }
      }
      .list{
        height: 100%;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 0px 0 0 30px;
        background: #f7f7f7;
        h1{
          font-size: 20px;
          color: #333;
          padding-left: 10px;
          line-height: 40px;
          background: #f7f7f7;
        }
        p{
          font-size: 18px;
          padding-left: 10px;
          line-height: 40px;
          border-top: 1px solid #f0f0f0;
          background: #fff;
          cursor: pointer;
        }
      }
    }
  }
</style>
